import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const ObjectFilter = () => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [selectedObjectId, setSelectedObjectId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const objects = useQuery({
    queryKey: 'objectsSelect',
    queryFn: async function () {
      const response = await axiosPrivate.get('/dictionary/objects2');
      return response.data.data;
    },
    enabled: !hasError,
    onSuccess: (data) => {
      if (data && data.length > 0) {
        let entries = Object.fromEntries(searchParams);
        if (Object.keys(entries).includes('objectId')) {
          setSelectedObjectId(searchParams.get('objectId'));
        }
      }
    },
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const handleChangeObject = (event) => {
    const {
      target: { value },
    } = event;
    searchParams.set('objectId', value);
    clearAll();
    setSelectedObjectId(value);
    setSearchParams(searchParams);
  };

  const clearAll = () => {
    let entries = Object.fromEntries(searchParams);
    Object.keys(entries).forEach((item) => {
      if (item.includes('block')) searchParams.delete(item);
    });
  };

  useEffect(() => {
    const { data } = objects;
    if (data && data?.length > 0) {
      let entries = Object.fromEntries(searchParams);
      if (Object.keys(entries).includes('objectId')) {
        setSelectedObjectId(searchParams.get('objectId'));
      } else {
        setSelectedObjectId('');
      }
    }
  }, [objects, searchParams]);

  return (
    <div className='flex flex-row items-center w-full'>
      <div className='w-[500px] ml-2'>
        <Grid container spacing={1} columns={16}>
          <Grid item={true} sm={6} xs={16}>
            <FormControl fullWidth color='formColor'>
              <InputLabel id='objectId-label'>
                {t('common.fields.objectName')}
              </InputLabel>
              <Select
                labelId='objectId-label'
                id='objectId-select'
                label={t('common.fields.objectName')}
                onChange={handleChangeObject}
                value={selectedObjectId}
                color='formColor'
                variant='outlined'
                role='presentation'
                MenuProps={{ id: 'objectId-select-menu' }}
                size='small'
              >
                {objects.isLoading || objects.isFetching ? (
                  <div className='circular-progress-box'>
                    <CircularProgress size={25} />
                  </div>
                ) : objects.data && objects.data.length > 0 ? (
                  objects.data.map((item, index) => (
                    <MenuItem
                      value={item.id}
                      key={index + 1}
                      className='small-size'
                    >
                      {item.name}
                    </MenuItem>
                  ))
                ) : (
                  <div>
                    <span className='no-data-found-wrapper select-box'>
                      <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                      {t('common.global.noDataFound')}
                    </span>
                  </div>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default memo(ObjectFilter);
